import * as constant from "../../../actions/layout/taxi-service/actionType"

const initialState = {
    pendingDriversList: [],
    deactivateDriversList: [],
    activeDriversList: [],
    billingPaymentList: [],
    tripHistoryList: [],
    ongoingTripList: [],
    completedTripList: [],
    cancelledTripList: [],
    vehicleTypesList: [],
    vehicleTypePricesList: [],
    waitingTimeList: [],
    commissionRateList: []
}

export const taxiServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.SET_PENDING_DRIVERS_LIST:
            return {
                ...state,
                pendingDriversList: action.payload
            }
        case constant.SET_DEACTIVATE_DRIVERS_LIST:
            return {
                ...state,
                deactivateDriversList: action.payload
            }
        case constant.SET_ACTIVE_DRIVERS_LIST:
            return {
                ...state,
                activeDriversList: action.payload
            }
        case constant.SET_ALL_BILLING_PAYMENT_LIST:
            return {
                ...state,
                billingPaymentList: action.payload
            }
        case constant.SET_ALL_TRIP_HISTORY_LIST:
            return {
                ...state,
                tripHistoryList: action.payload
            }
        case constant.SET_ALL_ONGOING_TRIP_LIST:
            return {
                ...state,
                ongoingTripList: action.payload
            }
        case constant.SET_ALL_COMPLETED_TRIP_LIST:
            return {
                ...state,
                completedTripList: action.payload
            }
        case constant.SET_ALL_CANCELLED_TRIP_LIST:
            return {
                ...state,
                cancelledTripList: action.payload
            }
        case constant.SET_ALL_VEHICLE_TYPES:
            return {
                ...state,
                vehicleTypesList: action.payload
            }
        case constant.SET_ALL_VEHICLE_TYPE_PRICES:
            return {
                ...state,
                vehicleTypePricesList: action.payload
            }
        case constant.SET_ALL_WAITING_TIME_LIST:
            return {
                ...state,
                waitingTimeList: action.payload
            }
        case constant.SET_ALL_COMMISSION_RATE_LIST:
            return {
                ...state,
                commissionRateList: action.payload
            }
        default:
            return state
    }
}