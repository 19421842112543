import ApiService from "./apiService"

export async function GetRevenueChartDataByOption(option, startDate, endDate) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/chart-data/${option}?startDate=${startDate}&endDate=${endDate}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetRevenueChartDataExportLink(startDate, endDate) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/reports?startDate=${startDate}&endDate=${endDate}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetWeekAdvertisementRevenueReport(startDate, endDate) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/revenue-report-ads/week?startDate=${startDate}&endDate=${endDate}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetMonthlyAdvertisementRevenueReport(
  thisMonth,
  lastMonth
) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/revenue-report-ads/monthly?thisMonth=${thisMonth}&lastMonth=${lastMonth}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetWeekTripRevenueReport(startDate, endDate) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/revenue-report-trip/week?startDate=${startDate}&endDate=${endDate}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetMonthlyTripRevenueReport(thisMonth, lastMonth) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/revenue-report-trip/monthly?thisMonth=${thisMonth}&lastMonth=${lastMonth}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function GetSelectedMonthEndRevenueByYearAndMonth(date) {
  const apiObject = {}
  apiObject.method = "GET"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/get/month-end-revenue?date=${date}`
  apiObject.body = null
  return await ApiService.callApi(apiObject)
}

export async function AddOrUpdateMonthEndRevenueDetail(date, obj) {
  const apiObject = {}
  apiObject.method = "POST"
  apiObject.authentication = true
  apiObject.endpoint = `revenue/handle/month-end-revenue?date=${date}`
  apiObject.body = obj
  apiObject.notGroup = true
  return await ApiService.callApi(apiObject)
}
