import * as constant from "../../../actions/layout/posts/actionType"

const initialState = {
    uploadedList: [],
    boostedList: [],
    modalList: [],
    pendingList: [],
    approvalList: [],
    rejectedList: []
}

export const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.SET_UPLOADED_POSTS_LIST:
            return {
                ...state,
                uploadedList: action.payload
            }
        case constant.SET_BOOSTED_POSTS_LIST:
            return {
                ...state,
                boostedList: action.payload
            }
        case constant.SET_POSTS_MODAL_DATA_LIST:
            return {
                ...state,
                modalList: action.payload
            }
        case constant.SET_PENDING_POSTS_DATA_LIST:
            return {
                ...state,
                pendingList: action.payload
            }
        case constant.SET_APPROVAL_POSTS_DATA_LIST:
            return {
                ...state,
                approvalList: action.payload
            }
        case constant.SET_REJECTED_POSTS_DATA_LIST:
            return {
                ...state,
                rejectedList: action.payload
            }
        default:
            return state
    }
}
