export const API_BASE_URL = "https://jsonplaceholder.typicode.com"

export const BASE_ROUTE_PATH = ""

export const ROUTE_DASHBOARD = "/home"

export const ACCESS_TOKEN = "quick-team-admin-access"

export const REFRESH_TOKEN = "quick-team-admin-refresh"

export const LOCAL_STORAGE_KEY = "userData"
export const USER_DATA = "userData"

export const categories = [
  { categoryId: 1, categoryName: "Properties" },
  { categoryId: 2, categoryName: "Electronics" },
  { categoryId: 3, categoryName: "Business" },
  { categoryId: 4, categoryName: "Services" },
  { categoryId: 5, categoryName: "Fashion" },
  { categoryId: 6, categoryName: "Agriculture" },
  { categoryId: 7, categoryName: "Education" },
  { categoryId: 8, categoryName: "Sports" },
  { categoryId: 11, categoryName: "Garden" },
  { categoryId: 12, categoryName: "Animals" },
  { categoryId: 13, categoryName: "Households" },
  { categoryId: 14, categoryName: "Vehicles" },
  { categoryId: 15, categoryName: "Proposals" },
  { categoryId: 16, categoryName: "Jobs & Others" },
  { categoryId: 19, categoryName: "TEST_19" }
]
