const getInitialUserData = () => {
  const userDataFromLocalStorage = localStorage.getItem("userData")
  return userDataFromLocalStorage ? JSON.parse(userDataFromLocalStorage) : {}
}

const initialState = {
  userData: getInitialUserData()
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName]
      }
    case "LOGOUT":
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload
      }
    default:
      return state
  }
}

export default authReducer
