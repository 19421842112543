export const SET_PENDING_DRIVERS_LIST = 'SET_PENDING_DRIVERS_LIST'
export const SET_DEACTIVATE_DRIVERS_LIST = 'SET_DEACTIVATE_DRIVERS_LIST'
export const SET_ACTIVE_DRIVERS_LIST = 'SET_ACTIVE_DRIVERS_LIST'
export const SET_ALL_BILLING_PAYMENT_LIST = 'SET_ALL_BILLING_PAYMENT_LIST'
export const SET_ALL_TRIP_HISTORY_LIST = 'SET_ALL_TRIP_HISTORY_LIST'
export const SET_ALL_ONGOING_TRIP_LIST = 'SET_ALL_ONGOING_TRIP_LIST'
export const SET_ALL_COMPLETED_TRIP_LIST = 'SET_ALL_COMPLETED_TRIP_LIST'
export const SET_ALL_CANCELLED_TRIP_LIST = 'SET_ALL_CANCELLED_TRIP_LIST'
export const SET_ALL_VEHICLE_TYPES = 'SET_ALL_VEHICLE_TYPES'
export const SET_ALL_VEHICLE_TYPE_PRICES = 'SET_ALL_VEHICLE_TYPE_PRICES'
export const SET_ALL_WAITING_TIME_LIST = 'SET_ALL_WAITING_TIME_LIST'
export const SET_ALL_COMMISSION_RATE_LIST = 'SET_ALL_COMMISSION_RATE_LIST'