const initialState = {
    totalUsers: 0,
    totalActivePosts: 0,
    totalDailyRevenue: 0,
    totalDailyPromotedPostCount: 0
}

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TOTAL_USERS':
            return {
                ...state,
                totalUsers: action.payload
            }
        case 'SET_TOTAL_ACTIVE_POSTS':
            return {
                ...state,
                totalActivePosts: action.payload
            }
        case 'SET_TOTAL_DAILY_REVENUE':
            return {
                ...state,
                totalDailyRevenue: action.payload
            }
        case 'SET_TOTAL_DAILY_PROMOTED_POSTS':
            return {
                ...state,
                totalDailyPromotedPostCount: action.payload
            }
        default:
            return state
    }
}
