// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import loading from "./loading"
import { dashboardReducer } from "./layout/dashboard/reducer"
import { userReducer } from "./layout/user/reducer"
import { postReducer } from "./layout/posts/reducer"
import { categoryReducer } from "./layout/category/reducer"
import { paymentReducer } from "./layout/payment/reducer"
import { taxiServiceReducer } from "./layout/taxi-service/reducer"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  loading,
  user: dashboardReducer,
  posts: dashboardReducer,
  dailyRevenue: dashboardReducer,
  dailyPromotedPosts: dashboardReducer,
  usersList: userReducer,
  postsList: postReducer,
  categoryList: categoryReducer,
  paymentChartList: paymentReducer,
  driversList: taxiServiceReducer
})

export default rootReducer
