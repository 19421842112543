import * as constant from "../../../actions/layout/payment/actionType"
import {apiService} from '../../../../services/payment'

const initialState = {
    // annual: apiService.getAllAnnualData()
}

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.SET_ALL_ANNUAL_DATA:
            return {
                ...state,
                annuallyData: action.payload
            }
        case constant.SET_ALL_MONTHLY_DATA:
            return {
                ...state,
                monthlyData: action.payload
            }
        case constant.SET_ALL_WEEKLY_DATA:
            return {
                ...state,
                weeklyData: action.payload
            }
        default:
            return state
    }
}
